<style scoped>
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s;
}

.btn {
	margin-bottom: 8px;
}

.fade-enter,
.fade-leave-to {
	opacity: 0;
}
</style>

<template>
	<errorContainer :error="erreur">
		<div class="container-layout px-0 animated fadeIn">
			<b-col md="12">
				<b-card class="card-border-blue-light">
					<b-row class="form">
						<b-col sm="12" md="12" lg="12" xl="12">
							<div class="card-collapsible px-0">
								<div
									class="header-card-collapsible d-flex flex-row align-items-center cursor-pointer"
									@click="handleCollapsibleHeader('work-general-information')"
								>
									<div class="title w-90">
										<b-icon icon="file-earmark-text" style="color: rgba(6, 38, 62, 0.84)" /><span class="ml-3">{{
											FormMSG(4, 'Work general information')
										}}</span>
									</div>
									<div class="cursor-pointer d-flex justify-content-end w-10">
										<component
											v-if="!configAccordion['work-general-information'].isVisible"
											:is="getIconLucide('ChevronDown')"
											color="#06263E"
											:size="22"
										/>
										<component
											v-if="configAccordion['work-general-information'].isVisible"
											:is="getIconLucide('ChevronUp')"
											color="#06263E"
											:size="22"
										/>
									</div>
								</div>
							</div>
							<b-collapse :visible="configAccordion['work-general-information'].isVisible">
								<div class="content-card-collapsible">
									<department-selection
										:is-submitted="isHandledCcn2642"
										@department-function-selector:departmentSelected="handleDepartmentChange"
										@department-function-selector:functionSelected="handleFunctionChange"
										burger-field
									/>
									<b-row>
										<b-col v-if="[0, 1, 3, 4].includes(analyticPeriodType)" sm="12" md="12" lg="3" xl="3">
											<b-form-group :label="labelCostCenterPreps">
												<treeselect
													v-model="contractTemplate.costCenterPrepsType"
													:multiple="false"
													:clearable="false"
													:options="costCenterPrepsOptions"
													:disable-branch-nodes="true"
													:class="{ 'is-invalid': isSubmitted && $v.contractTemplate.costCenterPrepsType.$error }"
												>
													<div slot="option-label" slot-scope="{ node }">
														<div class="treeselect-label" :title="node.label">{{ node.label }}</div>
													</div>
												</treeselect>
												<div v-if="isSubmitted && $v.contractTemplate.costCenterPrepsType.$error" class="invalid-feedback">
													{{ FormMSG(57, msgPrep) }}
												</div>
											</b-form-group>
										</b-col>
										<b-col v-if="[1, 2, 4].includes(analyticPeriodType)" sm="12" md="12" lg="3" xl="3">
											<b-form-group :label="FormMSG(55, 'Cost center shooting')">
												<treeselect
													v-model="contractTemplate.costCenterShootingType"
													:multiple="false"
													:clearable="false"
													:options="costCenterShootingOptions"
													:disable-branch-nodes="true"
													:class="{ 'is-invalid': isSubmitted && $v.contractTemplate.costCenterShootingType.$error }"
												>
													<div slot="option-label" slot-scope="{ node }">
														<div class="treeselect-label" :title="node.label">{{ node.label }}</div>
													</div>
												</treeselect>
												<div v-if="isSubmitted && $v.contractTemplate.costCenterShootingType.$error" class="invalid-feedback">
													{{ FormMSG(58, msgShooting) }}
												</div>
											</b-form-group>
										</b-col>
										<b-col v-if="[2, 3, 4].includes(analyticPeriodType)" sm="12" md="12" lg="3" xl="3">
											<b-form-group :label="FormMSG(56, 'Cost center wrap')">
												<treeselect
													v-model="contractTemplate.costCenterWrapType"
													:multiple="false"
													:clearable="false"
													:options="costCenterWrapOptions"
													:disable-branch-nodes="true"
													:class="{ 'is-invalid': isSubmitted && $v.contractTemplate.costCenterWrapType.$error }"
												>
													<div slot="option-label" slot-scope="{ node }">
														<div class="treeselect-label" :title="node.label">{{ node.label }}</div>
													</div>
												</treeselect>
												<div v-if="isSubmitted && $v.contractTemplate.costCenterWrapType.$error" class="invalid-feedback">
													{{ FormMSG(59, msgWrap) }}
												</div>
											</b-form-group>
										</b-col>
										<b-col sm="12" md="12" lg="3" xl="3">
											<b-form-group :label="FormMSG(60, 'Budget cost factor')">
												<b-form-input
													v-model="contractTemplate.companyCostFactor"
													type="number"
													step="0.01"
													min="1"
													placeholder="1.0"
												></b-form-input>
												<div class="info-message">
													<div>
														<component :is="getIconLucide('Info')" :size="16" />
													</div>
													<div class="label">{{ FormMSG(61, 'budget cost = salary*factor') }}</div>
												</div>
											</b-form-group>
										</b-col>
									</b-row>
								</div>
							</b-collapse>
							<memo-deal
								ref="memo"
								:edit-data="contractTemplate"
								:displayCopyPaste="true"
								:cost-center-preps-type-update="contractTemplate.costCenterPrepsType"
								:cost-center-shooting-type-update="contractTemplate.costCenterShootingType"
								:cost-center-wrap-type-update="contractTemplate.costCenterWrapType"
								:company-cost-factor-update="contractTemplate.companyCostFactor"
								:is-submitted="isSubmitted"
								:fill-class-container="''"
								:disable-save-deal-memo-template="disableSaveTemplate"
								:show-pay-code="showPayCode"
								@change="handleMemoDealChange"
								@memo-deal:fields:invalid="isInvalidateFields"
								@memo-deal:fields:clear-error="clearFieldsError"
								@memo-deal:save-template="saveTemplate"
								@memo-deal:department-function:required="handleCcn2642"
							/>
						</b-col>
					</b-row>
				</b-card>
			</b-col>
		</div>
	</errorContainer>
</template>

<script>
// import gql from 'graphql-tag'
import { store } from '@/store';

import { objReducer, removeAttributeTree, greaterThanZero, getDefaultValueCategoryTree } from '@/shared/utils';
import languageMessages from '@/mixins/languageMessages';
import { rendCurrency } from '~helpers';
import globalMixin from '@/mixins/global.mixin';
import moment from 'moment';
import { getContractTemplate, addUpdContractTemplate } from '@/cruds/contract.crud';
import * as icons from 'lucide-vue';
import { getBudgetHierarchicalCategoryJson } from '@/cruds/budget.crud';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import { validationMixin } from 'vuelidate';
import { required, decimal } from 'vuelidate/lib/validators';
import { isNil, generateSecureId } from '@/shared/utils';
import _ from 'lodash';

// Variable doit être dans la query : https://github.com/graphql/graphiql/issues/156

const payCodeKeys = [
	'baseSalary1PayCode',
	'baseSalary2PayCode',
	'baseSalary3PayCode',
	'baseSalary4PayCode',
	'dayOvt1PayCode',
	'dayOvt2PayCode',
	'dayOvt3PayCode',
	'weekOvt1PayCode',
	'weekOvt2PayCode',
	'weekOvt3PayCode',
	'nightTimePayCode',
	'restPayCode',
	'transportPayCode',
	'sundayPayCode',
	'seventhDayPayCode',
	'sixthDayPayCode',
	'beforeTimePayCode',
	'travelAllowancePayCode',
	'carAllowancePayCode',
	'phoneAllowancePayCode',
	'computerAllowancePayCode',
	'boxKitAllowancePayCode',
	'productionFeeAllowancePayCode',
	'lunchPerDiemPayCode',
	'dinerPerDiemPayCode',
	'hotelPerDiemPayCode',
	'abroadPerDiemPayCode'
];

export default {
	name: 'MemoDeals',
	mixins: [languageMessages, globalMixin, validationMixin],
	props: {
		showPayCode: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	components: {
		departmentSelection: () => ({
			component: import('@/components/DepartmentFunctionSelection')
		}),
		memoDeal: () => ({
			component: import('@/components/MemoDeal')
		}),
		Treeselect
	},
	data() {
		return {
			erreur: {},
			showMemoDeal: 0,
			editData: {
				type: Object,
				required: false,
				default: null
				//default: { department:10, Function:1010 }
			},
			contractTemplate: {
				defaultDayType: 0,
				dailyRate: 0,
				travelDailyRate: 0,
				hourlyRate: 35,
				overtimeRate: 0,
				nightTimeRate: 0,
				hourBeforeTimeRate: 0,
				kmRate: 0,
				lunchPerDiem: 0,
				hotelPerDiem: 0,
				dailyRightTransfer: 0,
				nightTimeStart: '2019-01-01T00:00:00Z',
				nightTimeEnd: '2019-01-01T00:00:00Z',
				minTimeBetweenDays: '2019-01-01T00:00:00Z',
				lunchMinimum: 0,
				totMinPerDayStr: '',
				totMinPerWeekStr: '',
				sixthDay: 100,
				seventhDay: 100,
				timeManagementType: 0,
				weeklyOvertime: false,
				workSixDays: 0,
				weeklyFiveDaysMinimumSalary: 995,
				weeklySixDaysMinimumSalary: 1141,
				weeklyFiveDaysEffNumHours: 43,
				weeklyFiveDaysEquNumHours: 46,
				weeklySixDaysEffNumHours: 52,
				weeklySixDaysEquNumHours: 56,
				weeklyBonusAmount: 800,
				weeklyFiveDaysRefSalary: 1501,
				weeklySixDaysRefSalary: 1985,
				weeklyOvtOneLimit: 35,
				weeklyOvtOneRate: 125,
				weeklyOvtTwoLimit: 43,
				weeklyOvtTwoRate: 150,
				weeklyOvtThreeLimit: 48,
				weeklyOvtThreeRate: 175,
				weeklyBaseEffNumHours: 39,
				hourlyRateAnnexThree: 23,
				weeklyBonusAmountFiveDays: 950,
				weeklyBonusAmountSixDays: 1200,
				weeklyBaseRefSalary: 1415,
				weeklyBaseMinimumSalary: 970,
				weeklyFiveDaysMinimumSalaryAnThree: 910,
				weeklySixDaysMinimumSalaryAnThree: 1140,
				transportRate: 0,
				transportPaidAfter: 0,
				ovtLimit1: 0,
				ovtLimit2: 0,
				ovtRate1: 100,
				ovtRate2: 100,
				sundayPublicHolidayFactor: 100,
				companyCostFactor: 1,
				minTimeBetweenWeek: 0,
				transportPaidAfterStr: '',
				ovtLimit1Str: '',
				ovtLimit2Str: '',
				minTimeBetweenWeekStr: '',
				totMinPerDayForContDay: 0,
				totMinPerDayForContDayStr: '00:00',
				lunchMinimumForContDay: 0,
				weeklyBaseEffNumHoursStr: '00:00',
				weeklyOvtOneLimitStr: '00:00',
				weeklyOvtTwoLimitStr: '00:00',
				weeklyOvtThreeLimitStr: '00:00',
				lunchMinimumStr: '00:00',
				lunchMinimumForContDayStr: '00:00',
				employeeStatus: 0,
				isAutomaticSentContract: true,
				costCenterPrepsType: 0,
				costCenterShootingType: 0,
				costCenterWrapType: 0,
				documentPackageId: 0,
				useCarAllowance: false,
				carAllowanceRate: 0,
				carAllowance: 0,
				useProductionFeeAllowance: false,
				productionFeeAllowanceRate: 0,
				productionFeeAllowance: 0,
				useDinnerPerDiem: false,
				dinnerPerDiem: 0,
				useComputerAllowance: false,
				computerAllowanceRate: 0,
				computerAllowance: 0,
				minimumHourGuaranteed: 0,
				minimumHourGuaranteedType: 0,
				minimumHourGuaranteedStr: '00:00',
				beforeCallTimeRate: 0,
				ifWorkAboveDailyHours: false,
				usePhoneAllowance: false,
				phoneAllowanceRate: 0,
				phoneAllowance: 0,
				useBoxKitAllowance: false,
				boxKitAllowanceRate: 0,
				boxKitAllowance: 0,
				useAbroadPerDiem: false,
				abroadPerDiem: 0,
				useHotelPerDiem: false,
				useLunchPerDiem: false,
				note: '',
				weeklyFiveDaysEffNumHoursStr: '00:00',
				weeklyFiveDaysEquNumHoursStr: '00:00',
				weeklySixDaysEffNumHoursStr: '00:00',
				weeklySixDaysEquNumHoursStr: '00:00',
				contractReference: '',
				contractType: '',
				dailyOvertime: false,
				ovtLimit: 0,
				ovtLimitContinuousDay: 0,
				ovtLimitStr: '00:00',
				ovtLimitContinuousDayStr: '00:00',
				monthlyRate: 0,
				useTravelDailyRate: false,
				baseSalary1PayCode: null,
				baseSalary2PayCode: null,
				baseSalary3PayCode: null,
				baseSalary4PayCode: null,
				dayOvt1PayCode: null,
				dayOvt2PayCode: null,
				dayOvt3PayCode: null,
				weekOvt1PayCode: null,
				weekOvt2PayCode: null,
				weekOvt3PayCode: null,
				nightTimePayCode: null,
				restPayCode: null,
				transportPayCode: null,
				sundayPayCode: null,
				seventhDayPayCode: null,
				sixthDayPayCode: null,
				beforeTimePayCode: null,
				travelAllowancePayCode: null,
				carAllowancePayCode: null,
				phoneAllowancePayCode: null,
				computerAllowancePayCode: null,
				boxKitAllowancePayCode: null,
				productionFeeAllowancePayCode: null,
				lunchPerDiemPayCode: null,
				dinerPerDiemPayCode: null,
				hotelPerDiemPayCode: null,
				abroadPerDiemPayCode: null,
				collectiveAgreement: '',
				echelon: '',
				analyticCode: '',
				cdiMonthlyReferenceSalary: 0,
				publicHolidayCalendar: null
			},
			departmentFunction: {
				department: 0,
				function: 0
			},
			errors: [],
			selected: [], // Must be an array reference!
			show: true,
			isSubmitted: false,
			configAccordion: {
				'work-general-information': {
					isVisible: true
				}
			},
			costCenterPrepsOptions: [],
			costCenterShootingOptions: [],
			costCenterWrapOptions: [],
			msgPrep: 'Please, select a prep',
			msgShooting: 'Please, select a shooting',
			msgWrap: 'Please, select a wrap',
			currentValidCategoryTree: null,
			isHandledCcn2642: false
		};
	},
	computed: {
		labelCostCenterPreps() {
			if (this.analyticPeriodType === 0) {
				return this.FormMSG(354, 'Cost center');
			} else {
				return this.FormMSG(54, 'Cost center preps');
			}
		},
		analyticPeriodType() {
			return store.state.myProject.analyticPeriodType;
		},
		getMemoDealCardTitle() {
			if (store.isPme()) {
				return this.FormMSG(110, 'HR template') + ' ' + rendCurrency('()');
			}

			return this.FormMSG(10, 'Memo deal') + ' ' + rendCurrency('()');
		},
		disableSaveTemplate() {
			if (parseInt(this.departmentFunction.department, 10) === 0 || parseInt(this.departmentFunction.function, 10) === 0) {
				return true;
			}

			return false;
		}
	},
	async created() {
		await this.initCostCenter(this.contractTemplate.department);
	},
	methods: {
		getSaveLabel() {
			if (store.isPme()) {
				return this.FormMSG(111, 'Save as HR template');
			}
			return this.FormMSG(11, 'Save Memo Deal as template');
		},
		getLastObjectWithNoChildren(id) {
			const targetObject = this.findObjectById(this.costCenterPrepsOptions, id);

			if (targetObject && targetObject.children && targetObject.children.length > 0) {
				return this.getLastObjectWithNoChildren(targetObject.children[0].id);
			}

			if (targetObject) {
				// console.log('Last Object ID:', targetObject.id);
				return targetObject.id;
			}

			console.log('Object not found');
			return null;
		},
		findObjectById(tree, id) {
			for (const node of tree) {
				if (node.id === id) {
					return node;
				}

				if (node.children && node.children.length > 0) {
					const foundObject = this.findObjectById(node.children, id);
					if (foundObject) {
						return foundObject;
					}
				}
			}

			return null;
		},
		async handleFunctionChange(element) {
			this.departmentFunction = {
				...this.departmentFunction,
				...element
			};

			//console.log("in handleFunctionChange this.contractTemplate :", this.contractTemplate);
			// loading template for new department/function
			if (parseInt(element.department, 10) > 0 && parseInt(element.function, 10) > 0) {
				this.showMemoDeal = 1;
				// load template
				const departmentNumber = parseInt(element.department, 10);
				const functionNumber = parseInt(element.function, 10);
				const catNumber = parseInt(element.category, 10);
				const catNumberId = this.getLastObjectWithNoChildren(catNumber);
				// console.log(catNumberId);
				await getContractTemplate(departmentNumber, functionNumber)
					.then(async (record) => {
						// console.log(record);
						if (record) {
							if (
								parseInt(record.costCenterPrepsType, 10) === 0 &&
								parseInt(record.costCenterShootingType, 10) === 0 &&
								parseInt(record.costCenterWrapType, 10) === 0
							) {
								// alert('ici');
								// const defaultValueCategoryTree = getDefaultValueCategoryTree(this.currentValidCategoryTree, catNumber, departmentNumber, 0);
								// alert(defaultValueCategoryTree);

								if (parseInt(record.costCenterPrepsType, 10) === 0) {
									this.contractTemplate.costCenterPrepsType = catNumberId;
								}

								if (parseInt(record.costCenterShootingType, 10) === 0) {
									this.contractTemplate.costCenterShootingType = catNumberId;
								}

								if (parseInt(record.costCenterWrapType, 10) === 0) {
									this.contractTemplate.costCenterWrapType = catNumberId;
								}
							} else {
								if (parseInt(record.costCenterPrepsType, 10) > 0) {
									this.contractTemplate.costCenterPrepsType = record.costCenterPrepsType;
								}

								if (parseInt(record.costCenterShootingType, 10) > 0) {
									this.contractTemplate.costCenterShootingType = record.costCenterShootingType;
								}

								if (parseInt(record.costCenterWrapType, 10) > 0) {
									this.contractTemplate.costCenterWrapType = record.costCenterWrapType;
								}
							}
							this.contractTemplate.companyCostFactor = record.companyCostFactor;

							record = {
								...record,
								costCenterPrepsType: this.contractTemplate.costCenterPrepsType,
								costCenterShootingType: this.contractTemplate.costCenterShootingType,
								costCenterWrapType: this.contractTemplate.costCenterWrapType,
								companyCostFactor: this.contractTemplate.companyCostFactor
							};

							record = this.initializedPayCode(record);
							// copy received data to child component
							this.$refs.memo.contract = {
								...this.$refs.memo.contract,
								...record,
								departmentId: departmentNumber,
								functionId: functionNumber
							};
							this.initializedPayCodeByRef('memo', record);
						}
					})
					.catch((error) => {
						this.erreur = error;
						this.errors.push(error);
					});
			} else {
				this.showMemoDeal = 0;
			}
		},
		async handleDepartmentChange(payload) {
			await this.initCostCenter(payload).then((result) => {
				if (result) {
					this.contractTemplate.costCenterPrepsType = 0;
					this.contractTemplate.costCenterShootingType = 0;
					this.contractTemplate.costCenterWrapType = 0;

					// copy received data to child component
					this.$refs.memo.contract = {
						...this.$refs.memo.contract,
						costCenterPrepsType: this.contractTemplate.costCenterPrepsType,
						costCenterShootingType: this.contractTemplate.costCenterShootingType,
						costCenterWrapType: this.contractTemplate.costCenterWrapType
					};
				}
			});
		},
		handleMemoDealChange(element) {
			this.contractTemplate = {
				...this.contractTemplate,
				...element
			};
		},
		async saveTemplate(payload) {
			return await this.saveMemoDealAsTemplate(payload);
		},
		async saveMemoDealAsTemplate(e) {
			e.preventDefault();
			this.isSubmitted = true;

			const isInvalid = await this.asyncIsinvalid();

			this.$v.$touch();
			if (this.$v.$invalid || isInvalid) {
				this.createToastForMobile(
					this.FormMSG(1, 'Error'),
					this.FormMSG(2, 'Please verify, some fields are required or format is not valid.'),
					'',
					'danger'
				);

				return;
			}

			let NewContractTemplate = objReducer(Object.keys(this.contractTemplate), this.contractTemplate);
			// convert elements to the right type as memoDeal returns strings
			NewContractTemplate.department = this.departmentFunction.department;
			NewContractTemplate.function = this.departmentFunction.function;
			NewContractTemplate.nightTimeStart = this.updateTime(NewContractTemplate.nightTimeStart);
			NewContractTemplate.nightTimeEnd = this.updateTime(NewContractTemplate.nightTimeEnd);
			NewContractTemplate.minTimeBetweenDays = this.updateTime(NewContractTemplate.minTimeBetweenDays);
			NewContractTemplate.monthlyRate = parseFloat(NewContractTemplate.monthlyRate);

			await addUpdContractTemplate(NewContractTemplate)
				.then((result) => {
					if (result) {
						this.createToastForMobile(this.FormMSG(3, 'Success'), this.FormMSG(297, 'Saved Memo Deal successfully as template'), '');
						this.isSubmitted = false;
					}
				})
				.catch((error) => {
					this.erreur = error;
					this.errors.push(error);
					this.isSubmitted = false;
				});
		},
		updateTime(value) {
			let now = new Date();
			const sValue = value.split(':');
			now = new Date(now.setHours(parseInt(sValue[0], 10)));
			now = new Date(now.setMinutes(parseInt(sValue[1], 10)));
			now = new Date(now.setSeconds(0));

			return `${moment(now).format('YYYY-MM-DDTHH:mm:ss')}Z`;
		},
		isInvalidateFields(payload) {
			this.isInvalid = payload;
		},
		async asyncIsinvalid() {
			return new Promise((resolve, reject) => {
				setTimeout(() => {
					resolve(this.isInvalid);
					reject(false);
				}, 200);
			});
		},
		clearFieldsError() {
			this.isSubmitted = false;
		},
		getIconLucide(name) {
			return icons[name];
		},
		handleCollapsibleHeader(key) {
			this.configAccordion[key].isVisible = !this.configAccordion[key].isVisible;
		},
		async initCostCenter(departmentId) {
			// set right name of costCenterPres, costCenterShooting, costCenterWrap
			await getBudgetHierarchicalCategoryJson(-2, true, 1).then((result) => {
				let costCenterPrepsOptions = [];
				let costCenterShootingOptions = [];
				let costCenterWrapOptions = [];

				const removeNoneChildrenTree = removeAttributeTree(result, 'children', null);
				this.currentValidCategoryTree = removeNoneChildrenTree;
				// console.log(removeNoneChildrenTree);
				costCenterPrepsOptions = removeNoneChildrenTree;
				costCenterShootingOptions = removeNoneChildrenTree;
				costCenterWrapOptions = removeNoneChildrenTree;

				this.costCenterPrepsOptions = [
					{
						id: 0,
						label: this.FormMSG(57, this.msgPrep)
					},
					...costCenterPrepsOptions
				];
				this.costCenterShootingOptions = [
					{
						id: 0,
						label: this.FormMSG(58, this.msgShooting)
					},
					...costCenterShootingOptions
				];
				this.costCenterWrapOptions = [
					{
						id: 0,
						label: this.FormMSG(59, this.msgWrap)
					},
					...costCenterWrapOptions
				];
			});

			return true;
		},
		initCostMsg() {
			this.costCenterPrepsOptions.push({
				id: 0,
				label: this.FormMSG(57, this.msgPrep)
			});

			this.costCenterShootingOptions.push({
				id: 0,
				label: this.FormMSG(58, this.msgShooting)
			});

			this.costCenterWrapOptions.push({
				id: 0,
				label: this.FormMSG(59, this.msgWrap)
			});
		},
		initializedPayCode(record) {
			record = {
				...record,
				baseSalary1PayCode: record.baseSalary1PayCode === 0 ? null : record.baseSalary1PayCode,
				baseSalary2PayCode: record.baseSalary2PayCode === 0 ? null : record.baseSalary2PayCode,
				baseSalary3PayCode: record.baseSalary3PayCode === 0 ? null : record.baseSalary3PayCode,
				baseSalary4PayCode: record.baseSalary4PayCode === 0 ? null : record.baseSalary4PayCode,
				dayOvt1PayCode: record.dayOvt1PayCode === 0 ? null : record.dayOvt1PayCode,
				dayOvt2PayCode: record.dayOvt2PayCode === 0 ? null : record.dayOvt2PayCode,
				dayOvt3PayCode: record.dayOvt3PayCode === 0 ? null : record.dayOvt3PayCode,
				weekOvt1PayCode: record.weekOvt1PayCode === 0 ? null : record.weekOvt1PayCode,
				weekOvt2PayCode: record.weekOvt2PayCode === 0 ? null : record.weekOvt2PayCode,
				weekOvt3PayCode: record.weekOvt3PayCode === 0 ? null : record.weekOvt3PayCode,
				nightTimePayCode: record.nightTimePayCode === 0 ? null : record.nightTimePayCode,
				restPayCode: record.restPayCode === 0 ? null : record.restPayCode,
				transportPayCode: record.transportPayCode === 0 ? null : record.transportPayCode,
				sundayPayCode: record.sundayPayCode === 0 ? null : record.sundayPayCode,
				seventhDayPayCode: record.seventhDayPayCode === 0 ? null : record.seventhDayPayCode,
				sixthDayPayCode: record.sixthDayPayCode === 0 ? null : record.sixthDayPayCode,
				beforeTimePayCode: record.beforeTimePayCode === 0 ? null : record.beforeTimePayCode,
				travelAllowancePayCode: record.travelAllowancePayCode === 0 ? null : record.travelAllowancePayCode,
				carAllowancePayCode: record.carAllowancePayCode === 0 ? null : record.carAllowancePayCode,
				phoneAllowancePayCode: record.phoneAllowancePayCode === 0 ? null : record.phoneAllowancePayCode,
				computerAllowancePayCode: record.computerAllowancePayCode === 0 ? null : record.computerAllowancePayCode,
				boxKitAllowancePayCode: record.boxKitAllowancePayCode === 0 ? null : record.boxKitAllowancePayCode,
				productionFeeAllowancePayCode: record.productionFeeAllowancePayCode === 0 ? null : record.productionFeeAllowancePayCode,
				lunchPerDiemPayCode: record.lunchPerDiemPayCode === 0 ? null : record.lunchPerDiemPayCode,
				dinerPerDiemPayCode: record.dinerPerDiemPayCode === 0 ? null : record.dinerPerDiemPayCode,
				hotelPerDiemPayCode: record.hotelPerDiemPayCode === 0 ? null : record.hotelPerDiemPayCode,
				abroadPerDiemPayCode: record.abroadPerDiemPayCode === 0 ? null : record.abroadPerDiemPayCode,
				publicHolidayCalendar: record.publicHolidayCalendar === 0 ? null : record.publicHolidayCalendar
			};

			return record;
		},
		initializedPayCodeByRef(parentRefName, record) {
			for (const key of payCodeKeys) {
				const el = this.$refs[parentRefName].$refs[this.generateTextId(key)];
				if (el) {
					el.payrollCodeSelected = record[key];
				}
			}
		},
		generateTextId(id) {
			return generateSecureId(id);
		},
		handleCcn2642(payload) {
			this.isHandledCcn2642 = payload;
		}
	},
	validations() {
		let result = {
			contractTemplate: {}
		};

		if ([0, 1, 3, 4].includes(this.analyticPeriodType)) {
			result.contractTemplate = _.assign(result.contract, {
				costCenterPrepsType: {
					required,
					decimal,
					min: greaterThanZero
				}
			});
		}

		if ([1, 2, 4].includes(this.analyticPeriodType)) {
			result.contractTemplate = _.assign(result.contract, {
				costCenterShootingType: {
					required,
					decimal,
					min: greaterThanZero
				}
			});
		}

		if ([2, 3, 4].includes(this.analyticPeriodType)) {
			result.contractTemplate = _.assign(result.contract, {
				costCenterWrapType: {
					required,
					decimal,
					min: greaterThanZero
				}
			});
		}
		return result;
	}
};
</script>
