var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("errorContainer", { attrs: { error: _vm.erreur } }, [
    _c(
      "div",
      { staticClass: "container-layout px-0 animated fadeIn" },
      [
        _c(
          "b-col",
          { attrs: { md: "12" } },
          [
            _c(
              "b-card",
              { staticClass: "card-border-blue-light" },
              [
                _c(
                  "b-row",
                  { staticClass: "form" },
                  [
                    _c(
                      "b-col",
                      { attrs: { sm: "12", md: "12", lg: "12", xl: "12" } },
                      [
                        _c("div", { staticClass: "card-collapsible px-0" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "header-card-collapsible d-flex flex-row align-items-center cursor-pointer",
                              on: {
                                click: function ($event) {
                                  return _vm.handleCollapsibleHeader(
                                    "work-general-information"
                                  )
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "title w-90" },
                                [
                                  _c("b-icon", {
                                    staticStyle: {
                                      color: "rgba(6, 38, 62, 0.84)",
                                    },
                                    attrs: { icon: "file-earmark-text" },
                                  }),
                                  _c("span", { staticClass: "ml-3" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.FormMSG(
                                          4,
                                          "Work general information"
                                        )
                                      )
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "cursor-pointer d-flex justify-content-end w-10",
                                },
                                [
                                  !_vm.configAccordion[
                                    "work-general-information"
                                  ].isVisible
                                    ? _c(_vm.getIconLucide("ChevronDown"), {
                                        tag: "component",
                                        attrs: { color: "#06263E", size: 22 },
                                      })
                                    : _vm._e(),
                                  _vm.configAccordion[
                                    "work-general-information"
                                  ].isVisible
                                    ? _c(_vm.getIconLucide("ChevronUp"), {
                                        tag: "component",
                                        attrs: { color: "#06263E", size: 22 },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]),
                        _c(
                          "b-collapse",
                          {
                            attrs: {
                              visible:
                                _vm.configAccordion["work-general-information"]
                                  .isVisible,
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "content-card-collapsible" },
                              [
                                _c("department-selection", {
                                  attrs: {
                                    "is-submitted": _vm.isHandledCcn2642,
                                    "burger-field": "",
                                  },
                                  on: {
                                    "department-function-selector:departmentSelected":
                                      _vm.handleDepartmentChange,
                                    "department-function-selector:functionSelected":
                                      _vm.handleFunctionChange,
                                  },
                                }),
                                _c(
                                  "b-row",
                                  [
                                    [0, 1, 3, 4].includes(
                                      _vm.analyticPeriodType
                                    )
                                      ? _c(
                                          "b-col",
                                          {
                                            attrs: {
                                              sm: "12",
                                              md: "12",
                                              lg: "3",
                                              xl: "3",
                                            },
                                          },
                                          [
                                            _c(
                                              "b-form-group",
                                              {
                                                attrs: {
                                                  label:
                                                    _vm.labelCostCenterPreps,
                                                },
                                              },
                                              [
                                                _c("treeselect", {
                                                  class: {
                                                    "is-invalid":
                                                      _vm.isSubmitted &&
                                                      _vm.$v.contractTemplate
                                                        .costCenterPrepsType
                                                        .$error,
                                                  },
                                                  attrs: {
                                                    multiple: false,
                                                    clearable: false,
                                                    options:
                                                      _vm.costCenterPrepsOptions,
                                                    "disable-branch-nodes": true,
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "option-label",
                                                        fn: function ({
                                                          node,
                                                        }) {
                                                          return _c("div", {}, [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "treeselect-label",
                                                                attrs: {
                                                                  title:
                                                                    node.label,
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    node.label
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ])
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    2657349975
                                                  ),
                                                  model: {
                                                    value:
                                                      _vm.contractTemplate
                                                        .costCenterPrepsType,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.contractTemplate,
                                                        "costCenterPrepsType",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "contractTemplate.costCenterPrepsType",
                                                  },
                                                }),
                                                _vm.isSubmitted &&
                                                _vm.$v.contractTemplate
                                                  .costCenterPrepsType.$error
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "invalid-feedback",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                57,
                                                                _vm.msgPrep
                                                              )
                                                            ) +
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    [1, 2, 4].includes(_vm.analyticPeriodType)
                                      ? _c(
                                          "b-col",
                                          {
                                            attrs: {
                                              sm: "12",
                                              md: "12",
                                              lg: "3",
                                              xl: "3",
                                            },
                                          },
                                          [
                                            _c(
                                              "b-form-group",
                                              {
                                                attrs: {
                                                  label: _vm.FormMSG(
                                                    55,
                                                    "Cost center shooting"
                                                  ),
                                                },
                                              },
                                              [
                                                _c("treeselect", {
                                                  class: {
                                                    "is-invalid":
                                                      _vm.isSubmitted &&
                                                      _vm.$v.contractTemplate
                                                        .costCenterShootingType
                                                        .$error,
                                                  },
                                                  attrs: {
                                                    multiple: false,
                                                    clearable: false,
                                                    options:
                                                      _vm.costCenterShootingOptions,
                                                    "disable-branch-nodes": true,
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "option-label",
                                                        fn: function ({
                                                          node,
                                                        }) {
                                                          return _c("div", {}, [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "treeselect-label",
                                                                attrs: {
                                                                  title:
                                                                    node.label,
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    node.label
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ])
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    2657349975
                                                  ),
                                                  model: {
                                                    value:
                                                      _vm.contractTemplate
                                                        .costCenterShootingType,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.contractTemplate,
                                                        "costCenterShootingType",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "contractTemplate.costCenterShootingType",
                                                  },
                                                }),
                                                _vm.isSubmitted &&
                                                _vm.$v.contractTemplate
                                                  .costCenterShootingType.$error
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "invalid-feedback",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                58,
                                                                _vm.msgShooting
                                                              )
                                                            ) +
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    [2, 3, 4].includes(_vm.analyticPeriodType)
                                      ? _c(
                                          "b-col",
                                          {
                                            attrs: {
                                              sm: "12",
                                              md: "12",
                                              lg: "3",
                                              xl: "3",
                                            },
                                          },
                                          [
                                            _c(
                                              "b-form-group",
                                              {
                                                attrs: {
                                                  label: _vm.FormMSG(
                                                    56,
                                                    "Cost center wrap"
                                                  ),
                                                },
                                              },
                                              [
                                                _c("treeselect", {
                                                  class: {
                                                    "is-invalid":
                                                      _vm.isSubmitted &&
                                                      _vm.$v.contractTemplate
                                                        .costCenterWrapType
                                                        .$error,
                                                  },
                                                  attrs: {
                                                    multiple: false,
                                                    clearable: false,
                                                    options:
                                                      _vm.costCenterWrapOptions,
                                                    "disable-branch-nodes": true,
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "option-label",
                                                        fn: function ({
                                                          node,
                                                        }) {
                                                          return _c("div", {}, [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "treeselect-label",
                                                                attrs: {
                                                                  title:
                                                                    node.label,
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    node.label
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ])
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    2657349975
                                                  ),
                                                  model: {
                                                    value:
                                                      _vm.contractTemplate
                                                        .costCenterWrapType,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.contractTemplate,
                                                        "costCenterWrapType",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "contractTemplate.costCenterWrapType",
                                                  },
                                                }),
                                                _vm.isSubmitted &&
                                                _vm.$v.contractTemplate
                                                  .costCenterWrapType.$error
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "invalid-feedback",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                59,
                                                                _vm.msgWrap
                                                              )
                                                            ) +
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "b-col",
                                      {
                                        attrs: {
                                          sm: "12",
                                          md: "12",
                                          lg: "3",
                                          xl: "3",
                                        },
                                      },
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              label: _vm.FormMSG(
                                                60,
                                                "Budget cost factor"
                                              ),
                                            },
                                          },
                                          [
                                            _c("b-form-input", {
                                              attrs: {
                                                type: "number",
                                                step: "0.01",
                                                min: "1",
                                                placeholder: "1.0",
                                              },
                                              model: {
                                                value:
                                                  _vm.contractTemplate
                                                    .companyCostFactor,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.contractTemplate,
                                                    "companyCostFactor",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "contractTemplate.companyCostFactor",
                                              },
                                            }),
                                            _c(
                                              "div",
                                              { staticClass: "info-message" },
                                              [
                                                _c(
                                                  "div",
                                                  [
                                                    _c(
                                                      _vm.getIconLucide("Info"),
                                                      {
                                                        tag: "component",
                                                        attrs: { size: 16 },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "label" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.FormMSG(
                                                          61,
                                                          "budget cost = salary*factor"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c("memo-deal", {
                          ref: "memo",
                          attrs: {
                            "edit-data": _vm.contractTemplate,
                            displayCopyPaste: true,
                            "cost-center-preps-type-update":
                              _vm.contractTemplate.costCenterPrepsType,
                            "cost-center-shooting-type-update":
                              _vm.contractTemplate.costCenterShootingType,
                            "cost-center-wrap-type-update":
                              _vm.contractTemplate.costCenterWrapType,
                            "company-cost-factor-update":
                              _vm.contractTemplate.companyCostFactor,
                            "is-submitted": _vm.isSubmitted,
                            "fill-class-container": "",
                            "disable-save-deal-memo-template":
                              _vm.disableSaveTemplate,
                            "show-pay-code": _vm.showPayCode,
                          },
                          on: {
                            change: _vm.handleMemoDealChange,
                            "memo-deal:fields:invalid": _vm.isInvalidateFields,
                            "memo-deal:fields:clear-error":
                              _vm.clearFieldsError,
                            "memo-deal:save-template": _vm.saveTemplate,
                            "memo-deal:department-function:required":
                              _vm.handleCcn2642,
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }